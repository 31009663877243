

































import {AppContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/AppContext';
import {PageContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/PageContext';
import {AppStoreKeys} from '../AppStoreKeys';
import {JsonLdService} from '../Service/JsonLdService';
import AppLoader from './Layout/AppLoader/AppLoader.vue';
import AppSidebarContainer from './Layout/AppSidebarContainer/AppSidebarContainer.vue';
import FooterMenu from './Layout/FooterMenu/FooterMenu.vue';
import SubPageListing from './Layout/SubPageListing/SubPageListing.vue';

export default {
    name: 'AppComponent',
    components: {
        AppLoader,
        SubPageListing,
        FooterMenu,
        AppSidebarContainer,
        Awards: () => import('./Misc/Awards/Awards.vue')
    },
    metaInfo()
    {
        return {
            script: [
                JsonLdService.makeOrganization(),
                JsonLdService.makeBreadcrumb()
            ]
        };
    },
    data()
    {
        return {
            hideSubPageElementIds: ['913','1', '3']
        };
    },
    computed: {
        breakpoint()
        {
            return this.$root.appContext.store.get(AppStoreKeys.BREAKPOINT);
        },
        appContext(): AppContext
        {
            return this.$root.appContext;
        },
        pageContext(): PageContext
        {
            return this.appContext.pageContext;
        }
    }
};
